
import lang from "~/mixins/lang.js";
export default {
  name: "Cookie",
  mixins: [lang],
  data() {
    return {
      cookieStorage: false,
      cookieClose: false,
    };
  },
  mounted() {
    this.defaultCookieSettings();
  },
  methods: {
    defaultCookieSettings() {
      if (localStorage.getItem("careemCookie") === null) {
        this.cookieStorage = true;
        this.cookieClose = true;
      }
    },
    close() {
      localStorage.setItem("careemCookie", "dismissed");
      this.cookieClose = false;
    },
  },
};
