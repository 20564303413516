import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0207d63a"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/usr/app/components/header/Header.vue').default,SmartBanner: require('/usr/app/components/smartBanner/SmartBanner.vue').default,Notification: require('/usr/app/components/notification/Notification.vue').default,Footer: require('/usr/app/components/footer/Footer.vue').default,Cookie: require('/usr/app/components/cookie/Cookie.vue').default})
