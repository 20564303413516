
import lang from "~/mixins/lang.js";
import device from "~/mixins/device.js";
export default {
  name: "SmartBanner",
  mixins: [lang, device],
  data() {
    return {
      smartBannerStorage: false,
      operatingSystemMacOS: true,
      operatingSystemOther: true,
      starNumber: 5,
    };
  },
  mounted() {
    this.operatingSystem();
    this.defaultSmartBannerSettings();
  },
  watch: {
    $route({ path }) {
      this.defaultSmartBannerSettings(path);
    },
  },
  methods: {
    operatingSystem() {
      if (this.isMobile) {
        this.operatingSystemMacOS = navigator.userAgent.indexOf("Mac") > 0;
        this.operatingSystemOther = !this.operatingSystemMacOS;
      }
    },
    defaultSmartBannerSettings(path = this.$route.path) {
      const bannerStorage = localStorage.getItem("smartBanner");
      const isExpress = path?.includes("/express");

      this.smartBannerStorage = bannerStorage === null && !isExpress;
    },
    close() {
      localStorage.setItem("smartBanner", "true");
      this.smartBannerStorage = false;
    },
  },
};
