
import vSelect from "vue-select";
import lang from "~/mixins/lang.js";
const qs = require("qs");
export default {
  name: "Footer",
  layout: "default",
  mixins: [lang],
  components: {
    vSelect,
  },
  props: {
    locationsOptions: {
      type: Array,
      default: () => [],
    },
    languagesOptions: {
      type: Array,
      default: () => [],
    },
    selectedLocations: {
      type: Object,
      default() {},
    },
    selectedLanguages: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      footerNavigation: {},
      selectedLanguage: {},
      selectedLocation: {},
    };
  },
  async fetch() {
    const query = qs.stringify(
      {
        populate: ["navigation.links", "helpCenter"],
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    try {
      const { data: dataNavigation } = await this.$axios.get(
        `/footer-navigation?${query}&locale=${
          this.activeLang && this.activeLang.length > 0
            ? this.activeLang
            : this.browserLang && this.browserLang.length > 0
            ? this.browserLang
            : "en-AE"
        }`
      );
      this.footerNavigation = dataNavigation?.data?.attributes;
    } catch {}
  },
  methods: {
    handleLocation(e) {
      this.$emit("handleLocation", e);
    },
    handleLanguage(e) {
      this.$emit("handleLanguage", e);
    },
  },
  watch: {
    selectedLocations() {
      this.selectedLocation = this.selectedLocations;
    },
    selectedLanguages() {
      this.selectedLanguage = this.selectedLanguages;
    },
  },
};
