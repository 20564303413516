
import lang from "~/mixins/lang.js";
export default {
  name: "Notification",
  mixins: [lang],
  data() {
    return {
      notificationStorage: false,
    };
  },
  async fetch() {},
  mounted() {
    this.defaultNotificationSettings();
  },
  methods: {
    defaultNotificationSettings() {
      if (sessionStorage.getItem("notification") === null) {
        this.notificationStorage = true;
      }
    },
    close() {
      sessionStorage.setItem("notification", "true");
      this.notificationStorage = false;
    },
  },
};
