
import lang from "~/mixins/lang.js";
import device from "~/mixins/device.js";
export default {
  name: "Download",
  mixins: [lang, device],
  props: {
    vertical: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      operatingSystemMacOS: true,
      operatingSystemOther: true,
      links: {
        ios: "",
        android: "",
        other: "",
      },
    };
  },
  mounted() {
    this.operatingSystem();
    this.links.ios = this.queryParams(this.download.iOS);
    this.links.android = this.queryParams(this.download.android);
    this.links.other = this.queryParams(this.download.other);
  },
  methods: {
    operatingSystem() {
      if (this.isMobile) {
        this.operatingSystemMacOS = navigator.userAgent.indexOf("Mac") > 0;
        this.operatingSystemOther = !this.operatingSystemMacOS;
      }
    },
    queryParams(input_url) {
      const addGoogleQueryParamsToURL = (_url, _queryParams) => {
        const urlObj = new URL(_url);
        const existingSearchParams = new URLSearchParams(urlObj.search);
        const allSearchParams = new URLSearchParams();
        // Add google query parameter from the provided object
        for (const param in _queryParams) {
          if (param === "gclid" || param === "gbraid") {
            allSearchParams.set(param, _queryParams[param]);
          }
        }

        existingSearchParams.forEach((value, key) => {
          allSearchParams.append(key, value);
        });

        // Update the search property of the URL object
        urlObj.search = allSearchParams.toString();
        return urlObj.toString();
      };

      return addGoogleQueryParamsToURL(input_url, this.$route.query);
    },
  },
};
