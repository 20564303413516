
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import lang from "~/mixins/lang.js";

export default {
  name: "default",
  mixins: [lang],
  data() {
    return {
      selectedLocations: {},
      selectedLanguages: {},
      languagesOptions: [],
    };
  },
  methods: {
    ...mapActions([
      "fetchSiteSetting",
      "fetchLocaledData",
      "fetchLocalesLocations",
    ]),
    handleLocation(e) {
      this.selectedLocations = e;
      this.languagesOptions = this.selectedLocations.availableLanguages;
      this.selectedLanguages = this.selectedLocations.availableLanguages[0];

      this.activeLang = this.selectedLocations.availableLanguages[0].iso;
      window.location.pathname = `/${this.selectedLanguages.iso}/${
        this.$route.path.split('/').slice(2).join('/')?.toLowerCase() || ""
      }`;
    },
    handleLanguage(e) {
      this.selectedLanguages = e;
      this.activeLang = e.iso;
      window.location.pathname = `/${e.iso}/${
        this.$route.path.split('/').slice(2).join('/')?.toLowerCase() || ""
      }`;
    },
  },
  computed: {
    ...mapGetters([
      "getDefaultLang",
      "getDefaultLocation",
      "getLocalesLocations",
    ]),
    locationsOptions() {
      const defaultLocation = this.getDefaultLocation?.toLowerCase();
      const defaultLang = this.getDefaultLang.toUpperCase();
      const pathLang = this.$route.path.split("/")[1]?.toLowerCase();

      const findLocation = (location) => {
        return (
          location.title?.toLowerCase() === defaultLocation &&
          location.availableLanguages.some(
            (language) => language.iso.toUpperCase() === defaultLang
          )
        );
      };

      if (this.$route.path === "" || this.$route.path === "/") {
        const defaultLoc = this.getLocalesLocations.find(findLocation);
        if (defaultLoc) {
          const defaultLangObj = defaultLoc.availableLanguages.find(
            (lang) => lang.iso.toUpperCase() === defaultLang
          );
          if (defaultLangObj) {
            this.selectedLocations = defaultLoc;
            this.selectedLanguages = defaultLangObj;
            this.languagesOptions = defaultLoc.availableLanguages;
            this.activeLang = defaultLangObj.iso;
          }
        }
      } else if (pathLang) {
        const foundLoc = this.getLocalesLocations.find((location) => {
          return location.availableLanguages.some(
            (language) => language.iso?.toLowerCase() === pathLang
          );
        });
        if (foundLoc) {
          const foundLangObj = foundLoc.availableLanguages.find(
            (lang) => lang.iso?.toLowerCase() === pathLang
          );
          if (foundLangObj) {
            this.selectedLocations = foundLoc;
            this.selectedLanguages = foundLangObj;
            this.languagesOptions = foundLoc.availableLanguages;
            this.activeLang = foundLangObj.iso;
          }
        }
      }

      return this.getLocalesLocations;
    },
  },
  created() {
    this.fetchSiteSetting();
    this.fetchLocalesLocations();
    this.fetchLocaledData(this.$utils.helper.getLangFromPath(this.$route.path));
  },
};
