const MOBILE_WIDTH = 1170;
export default {
  data() {
    return {
      isMobile: false,
    }
  },
  mounted() {
    this.breakpoints();
    window.addEventListener("resize", this.breakpoints, {
      passive: true,
    });
  },
  methods: {
    breakpoints() {
      this.isMobile = window.innerWidth < MOBILE_WIDTH;
    },
  },
}